<template>
  <q-dialog
      v-model="isOpen"
      :maximized="false"
  >
    <q-card
        class="relative"
        style="min-width: 40vh; max-width: 100vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <q-btn
            v-if="slide !== 'main'"
            text-color="dark"
            size="sm"
            icon="arrow_back"
            no-caps
            flat
            unelevated
            @click="handleBack"
        />

        <q-space/>

        <q-btn
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            flat
            unelevated
            @click="close"
        />
      </q-card-section>

      <h4 class="q-mt-none text-center">
        {{ $t('Create shipping plan') + ':' }}
      </h4>

      <form-builder ref="formBuilder" :schema="schema" class="q-px-md"/>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Cancel')"
            @click="close"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            :disable="disable"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'ShippingPlanImportModal',
  emits: ['close'],
  components: {},
  data () {
    return {
      disable: true,
      isOpen: false,
      slide: 'main',
      slides: [
        {
          id: 'orders',
          title: 'File',
          icon: 'table_view'
        },
        {
          id: 'wholesale',
          title: 'Ecommerce Platform',
          icon: 'local_offer'
        }
      ],
      model: {
        warehouse: null,
        source: null,
        place: null
      },
      types: {
        room: 'Orderadmin\\Storage\\Entity\\Place\\Room',
        row: 'Orderadmin\\Storage\\Entity\\Place\\Row',
        section: 'Orderadmin\\Storage\\Entity\\Place\\Section',
        static: 'Orderadmin\\Storage\\Entity\\Place',
        dynamic: 'Orderadmin\\Storage\\Entity\\Place\\Dynamic',
        employee: 'Orderadmin\\Storage\\Entity\\Place\\Employee',
        sorting: 'Orderadmin\\Storage\\Entity\\Place\\Sorting',
        distribution: 'Orderadmin\\Storage\\Entity\\Place\\Distribution',
        distribution_rejected: 'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected',
        assembly: 'Orderadmin\\Storage\\Entity\\Place\\Assembly',
        universal: 'Orderadmin\\Storage\\Entity\\Place\\Universal',
        pallet: 'Orderadmin\\Storage\\Entity\\Place\\Pallet',
        defected: 'Orderadmin\\Storage\\Entity\\Place\\Defected'
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                hasResetBtn: true,
                value: this.model.warehouse,
                field: 'warehouse',
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  if (this.options?.query && this.options?.query.warehouse) {
                    query.filter = [...this.options.query.warehouse, ...query.filter]
                  }

                  const values = ['client', 'virtual']
                  query.filter.push({ field: 'type', type: 'in', values })

                  return this.$service.warehouse.getAll(query)
                },
                onChange: (value) => {
                  this.model.warehouse = value
                  this.disabled()
                }
              },
              {
                ref: 'source',
                type: 'multiselect',
                label: this.$t('Ecommerce platform'),
                field: 'source',
                value: this.model.source,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                disabled: !this.model.warehouse,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id}) / ${row.handler} / ${row._embedded.owner.name}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] },
                      { type: 'eq', field: 'owner', value: this.model.warehouse._embedded.owner.id }
                    ]
                  }

                  return this.$service.iSource.getAll(query)
                },
                onChange: (source) => {
                  this.model.source = source
                  this.disabled()
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Select a storage location'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'place',
                value: this.model.place,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    const path = this.loadPath(row)
                    return `${path.join(' - ')} (${row.code}*) (${row.type})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'normal'] }
                    ]
                  }

                  if (this.type) {
                    query.filter.push({ type: 'eq', field: 'type', value: this.type })
                  }

                  const value = this.model.warehouse.type === 'virtual'
                      ? this.getId(this.model.warehouse._embedded.parent)
                      : this.model.warehouse.id

                  query.filter.push({ type: 'eq', field: 'warehouse', value })

                  return this.$service.storagePlace.getAll(query)
                },
                onChange: (place) => {
                  this.model.place = place
                  this.disabled()
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addOrderToList'
    ]),
    loadPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    handleSlide (slide) {
      this.slide = slide
    },
    handleBack () {
      this.slide = 'main'
    },
    open () {
      this.slide = 'main'
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    disabled () {
      if (!this.model.source || !this.model.warehouse || !this.model.place) {
        this.disable = true
        return
      }
      this.disable = false
      return
    },
    getId (entity = {}) {
      if (!entity) {
        return undefined
      }

      return entity.id
    },
    save () {
      const data = {
        state: 'pending',
        source: this.model.source.id,
        sourceData: {
          asyncMode: {
            'Orderadmin\\Integrations\\Amazon\\Service\\ShippingPlans': true
          },
          filters: {
            warehouse: this.model.warehouse.id,
            place: this.model.place.id
          }
        }
      }

      return this.$service.integrationImport.save(data)
          .then(() => {
            this.close()
            this.$emit('close')
          })
    }
  }
}
</script>
