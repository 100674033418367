<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Shipping Plans')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            color="positive"
            text-color="white"
            :label="$t('Import')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="!hasBaseAccess"
            @click="openImportShippingPlan"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilter"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="row q-pa-none">

        <status-filter
            class="hide-on-mobile"
            :outside-selected-status="selectedStatusFilter"
            :statuses="shippingPlanStates"
            @on-change="onStatusFilter"
        />

        <div class="col">
          <q-table
              v-if="shippingPlans"
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="shippingPlans"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="shippingPlansLoading"
              :filter="filter"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>
            <template
                v-slot:body="props"
                class="clickable"
            >
              <shipping-plans-table-row
                  :data="props"
                  :row="props.row"
                  :column="props.column"
                  @dblclick="openOrder(props.row)"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>

    <shipping-plan-import-modal ref="shippingPlanImport" @close="refreshItems"/>
  </div>
</template>

<script>

// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import Search from '../../components/search/Search'
import StatusFilter from '../../components/filters/StatusFilter'
import ShippingPlansTableRow from '../../components/amazon/ShippingPlansTableRow.vue'

//Mixins
import TableMixin from '../../components/global/TableMixin.vue'

// Configs
import orderStatusMatrix from './../../config/OrderMatrix'

// Helpers
import { buildQuery } from './../../utils/query-utils'
import ShippingPlanImportModal from '../../components/amazon/ShippingPlanImportModal'

export default {
  name: 'ShippingPlans',
  components: {
    ShippingPlanImportModal,
      FiltersCollapse,
    Search,
    StatusFilter,
    ShippingPlansTableRow
  },
  mixins:[
      TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      totalPlans: 0,
      isOpenFilter: false,
      activatedFields: [
        'id',
          'dates.from',
          'dates.to',
          'warehouse',
      ],
      externalFilter: {
        state: null
      },
      shippingPlanStates: [
        {
          id:1,
          group: 'All',
          color: 'white',
          buttons: [
            { id: 'new', title: 'New',  editableField: false, color: 'yellow-3' },
            { id: 'confirmed', title: 'Confirmed',  editableField: false, color: 'positive' },
            { id: 'error', title: 'Error', editableField: false, color: 'red-4' }
          ]
        }
      ],

    }
  },
  mounted() {
    this.loadDefaultItems()

  },
  computed: {
    ...mapGetters([
      'shippingPlans',
        'shippingPlansLoading',
      'shippingPlanItemsNumber',
      'hasBaseAccess'
    ]),
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Shipping Plan'),
          name: 'shippingPlan',
          align: 'left',
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },

        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left',
        },
        {
          label: this.$t('Creation Date'),
          name: 'creationDate',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
      ]
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalPlans)
          : this.$t('Filter')
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state' && !filter.alias
      })

      if (status && status.values) {
        return status.values
      }

      return status
          ? [status.value]
          : []
    },
  },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalPlans)
          : this.$t('Filter')
    },
    externalSchema () {
      return [
        {
          type: 'select',
          label: this.$t('Shipping status'),
          wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          value: this.externalFilter.state,
          field: 'state',
          hasResetBtn: true,
          options: this.drStates,
          customLabel: (row) => {
            if (row && typeof row === 'object') {
              return row.name
            }

            return row
          },
          onChange: (state) => {
            this.externalFilter.state = state && state.code
          }
        }
      ]
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state' && !filter.alias
      })

      if (status && status.values) {
        return status.values
      }

      return status
          ? [status.value]
          : []
  },
  methods: {
    ...mapActions([
      'loadShippingPlans',
    ]),
    ...mapMutations([
      'addWarningNotification'
    ]),
    loadDefaultItems () {
      this.onRequest({ pagination: {} })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      let updatedFilter = [
        ...filter
      ]

      if (this.externalFilter.state) {
        updatedFilter.push({
          type: 'like',
          field: 'cache::text',
          value: `%${this.externalFilter.state}%`
        })
      }

      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter: updatedFilter, page: 1 }})
    },
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'date',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      this.loadShippingPlans(query)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadShippingPlans(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
            this.totalPlans = totalItems
            if (totalItems <= 0) {
              this.addWarningNotification('No orders found')
            }
          })
      },
    openImportShippingPlan () {
      this.$refs.shippingPlanImport.open()
    },
    openOrder (plan) {
      this.$router.push(`/apps/apps/amazon/shipping-plans/${plan.id}`)
    }
  }
}
</script>