<template>
  <q-tr
      :props="data"
      class="clickable"
      :style="isLockedOrder && 'opacity: 0.7;'"
      @dblclick="handleDBClick"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex +  1 }}
    </q-td>

    <q-td
        key="id"
        :props="data"
    >
      <div class="row items-center">
        <h5 class="text-subtitle1 q-ma-none q-mr-sm text-weight-bold">
          {{ data.row.id }}
        </h5>

        <q-icon
            :name="isLockedOrder ? 'lock' : 'lock_open'"
            :color="isLockedOrder ? 'negative' : 'positive'"
        />
      </div>
    </q-td>

    <q-td
        key="shippingPlan"
        :props="data"
        class="text-caption"
        style="max-width: 200px; word-wrap: anywhere; white-space: normal !important;"
    >
      <p
          class="q-my-none"
      >
        {{ data.row.name }}

        <span  v-if="data.row.extId">
            {{ data.row.extId }}
        </span>
      </p>
    </q-td>

    <q-td
        key="state"
        :props="data"
    >
      <div style="max-width: 200px">
        <super-dropdown :model="schema" />
      </div>
    </q-td>

    <q-td
        key="warehouse"
        :props="data"
    >
      <div
          v-if="data.row._embedded?.warehouse"
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        {{ data.row._embedded.warehouse.name }} ({{ data.row._embedded.warehouse.id }})
      </div>
      <div
          v-else
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        <p>-</p>
      </div>
    </q-td>

    <q-td
        key="creationDate"
        :props="data"
    >
      <div
          v-if="data.row?.date?.date"
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        {{ $moment(data.row.date.date).format(appOptions.formats.fullDate)}}
      </div>
      <div
          v-else
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        <p>-</p>
      </div>
    </q-td>

    <q-td
        key="order"
        :props="data"
    >
      <div
          v-if="data.row._embedded?.order"
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        <div style="max-width: 200px">
          <super-dropdown :model="dropdownModel" />

          <q-tooltip class="q-px-sm text-left" v-if="data.row._embedded.orderErrors">
            <ul class="q-ma-none q-pl-md" style="max-width: 350px">
              <li class="text-caption text-weight-light" v-for="(orderError, i) in getOrderErrors" :key="i">
                {{ orderError }}
              </li>
            </ul>
          </q-tooltip>
          <ul class="q-mt-sm q-pa-none">
            <li>
              <span>{{ $t('Order ID') + ': ' }}</span>

              <strong>{{ data.row._embedded.order.id }}</strong>
            </li>

            <li v-if="data.row._embedded.order.extId">
              <span>{{ $t('Order extId') + ': ' }}</span>

              <strong>{{ data.row._embedded.order.extId || '--' }}</strong>
            </li>
          </ul>
        </div>
      </div>
      <div
          v-else
          style="max-width: 140px; word-wrap: break-word; white-space: normal !important;"
      >
        <p>-</p>
      </div>
    </q-td>

  </q-tr>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Helpers
import { getStateColors } from '../../helpers/helpers'
import deliveryRequestStatusMatrix from '../../config/DeliveryRequestMatrix'
import orderStatusMatrix from '../../config/OrderMatrix'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown'

// Utils
import { doCopy } from '../../utils/copy-utils'

export default {
  name: 'ShippingPlansTableRow',
  emits: ['dbclick'],
  components: {
    SuperDropdown
  },
  props: {
    noCustomer: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    },
    row: {
      type: Object,
      default () {
        return {}
      }
    },
    column: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      drStatuses: deliveryRequestStatusMatrix,
      statuses: orderStatusMatrix,
      stateColors: getStateColors(orderStatusMatrix),
      shippingPlanStates: [
        {
          buttons: [
            { id: 'new', title: 'New', transitions: ['confirmed'], color: 'yellow-3' },
            { id: 'confirmed', title: 'Confirmed', transitions: [], color: 'positive' },
            { id: 'error', title: 'Error', transitions: ['confirmed'], color: 'red-4' }
          ]
        }
      ],
      drStateColors: getStateColors(deliveryRequestStatusMatrix),
      paymentStateColors: {
        missing: 'default',
        payment_waiting: 'warning',
        received: 'info',
        partly_payed: 'warning',
        payed: 'success'
      }
    }
  },
  computed: {
    ...mapGetters([
      'lockedOrders',
      'deliveryServices',
      'appOptions'
    ]),
    schema () {
      const value = this.shippingPlanStates[0].buttons.find(({ id }) => id === this.data.row.state) || { id: 'new', title: 'New' }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {


          this.$service.shippingPlan.save({ state }, this.data.row.id)
              .then(item => {
                this.upsertShippingPlan(item)
              })
        },
        value,
        options: this.shippingPlanStates
      }

      return option
    },
    drDropdownModel () {
      const deliveryRequest = this.row._embedded.deliveryRequest

      if (!deliveryRequest) {
        return null
      }

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          this.saveDeliveryRequestByPayload({ id: deliveryRequest.id, data: { state } })
              .then(item => {
                this.setOrderFromList({
                  ...this.row,
                  _embedded: {
                    ...this.row._embedded,
                    deliveryRequest: item
                  }
                })
              })
        },
        value: { id: 'pending_queued', title: 'Pending Queued' },
        options: this.drStatuses
      }

      if (deliveryRequest && deliveryRequest.state !== option.value.id) {
        this.drStatuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === deliveryRequest.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      return option
    },
    dropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        editableFields: false,
        disabledLabels: true,
        onClick: (state) => {
          this.$service.order.save({ state }, this.row.id, this.row.type)
              .then(item => {
                this.setOrderFromList(item)
              })
        },
        value: this.statuses[0].buttons[0],
        options: this.statuses
      }

      this.statuses.find(group => {
        let status = group.buttons.find(btn => {
          return btn.id === this.row.state
        })

        if (status) {
          option.color = group.color
          option.value = status
        }

        return status
      })

      return option
    },
    isLockedOrder () {
      return this.lockedOrders.includes(this.row.id)
    },
    hasDeliveryRequestCurrency () {
      return this.row._embedded.deliveryRequest._embedded && this.row._embedded.deliveryRequest._embedded.currency
    },
    hasDeliveryServiceLogo () {
      return this.row._embedded.deliveryRequest._embedded &&
          this.loadCarrierByLink(this.row._embedded.deliveryRequest._embedded.deliveryService) &&
          this.loadCarrierByLink(this.row._embedded.deliveryRequest._embedded.deliveryService).logo
    },
    getOrderErrors () {
      if (Array.isArray(this.row.orderErrors)) {
        return this.row.orderErrors
      }

      return [
        this.row.orderErrors
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setOrderFromList'
    ]),
    ...mapActions([
      'saveDeliveryRequestByPayload'
    ]),
    doCopy (content) {
      return doCopy(content)
    },
    handleDBClick () {
      this.$emit('dbclick', this.data.row)
    },
    baseName (str) {
      let base = str.substring(str.lastIndexOf('/') + 1)

      if (base.lastIndexOf('.') !== -1) {
        base = base.substring(0, base.lastIndexOf('.'))
      }

      return base
    },
    loadCarrierByLink (deliveryService) {
      if (!deliveryService) {
        return null
      }

      let id = this.baseName(deliveryService._links.self.href)

      return this.deliveryServices[id]
    }
  }
}
</script>
